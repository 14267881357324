<template>
   <div class="obi-nf" :class="{ 'obi-nf--compact': compact }">
      <img class="obi-nf-image" :src="getImgUrl(type)" alt="alt" v-if="type" :height="imageHeight" />
      <div class="obi-nf-title" v-if="title" v-html="title" />
      <div class="obi-nf-description" v-if="description" v-html="description" />
   </div>
</template>

<script>
export default {
   name: 'ObiNotFound',
   props: {
      type: {
         type: String,
      },
      title: {
         type: String,
         required: true,
      },
      description: {
         type: String,
         required: false,
      },
      compact: {
         type: Boolean,
         required: false,
      },
      imageHeight: {
         type: [String, Number],
         required: false,
      },
   },
   methods: {
      getImgUrl(type) {
         const images = require.context('../../assets/images/svg/', false, /\.svg$/);

         return images(`./${type}_not_found_icon.svg`);
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-nf {
   gap: 12px;
   display: flex;
   text-align: center;
   align-items: center;
   flex-direction: column;
   padding: 4.5rem 1.75rem;
   justify-content: center;

   &-image {
      margin-bottom: 20px;
   }

   &-title {
      color: var(--primary-color) !important;
      font-size: 20px;
      margin-bottom: 12px;
   }

   &-description {
      color: #777d96;
      font-size: 13px;
   }

   &.obi-nf--compact {
      padding: 2rem 1.75rem;

      .obi-nf {
         &-image {
            margin-bottom: 0;
         }

         &-title {
            margin-bottom: 0;
         }
      }
   }
}
</style>
